import Swiper from 'swiper/bundle'

const singleGuitar = () => {
  const single = document.querySelector('.guitar-single')

  if (single) {
    guitarGallery()
  }
}

const guitarGallery = () => {
  let mainGallery = null
  let mainGalleryThumb = null

  const initializeSwiper = () => {
    mainGalleryThumb = new Swiper('.guitar-slider__sub', {
      spaceBetween: 3,
      loop: true,
      slidesPerView: 'auto',
      freeMode: true,
      watchSlidesProgress: true,
    })

    mainGallery = new Swiper('.guitar-slider__main', {
      spaceBetween: 3,
      loop: true,
      autoHeight: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: mainGalleryThumb,
      },
    })
  }

  const handleResize = () => {
    const width = window.innerWidth

    if (width > 768) {
      if (mainGalleryThumb) {
        mainGalleryThumb.destroy(true, true)
        mainGalleryThumb = null
      }
      if (mainGallery) {
        mainGallery.destroy(true, true)
        mainGallery = null
      }
    } else if (!mainGalleryThumb || !mainGallery) {
      initializeSwiper()
    }
  }

  if (window.innerWidth < 768) {
    initializeSwiper()
  }

  window.addEventListener('resize', handleResize)
}

export { singleGuitar }
